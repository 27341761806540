import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../layout/layout"
import SEO from "../components/seo"

const BlogPostTemplate = ({ data }) => {
    const info = data.wordpress.post;
    return (
        <Layout>
            <SEO
                title={info.title}
                description={info.excerpt}
            />
            <h1>{info.title}</h1>
            <p>
            Written by {info.author.name} on {info.date}
            </p>
            <Img
            sizes={info.featuredImage.mediaItemUrl}
            alt={info.title}
            style={{ maxHeight: 450 }}
            />
            <div
            style={{ marginTop: 20 }}
            />
            {info.content}
        </Layout>
    )
}
export default BlogPostTemplate

export const query = graphql`
query ($id: ID!) {
    wordpress {
        post(id: $id) {
            id
            title
            slug
            content
            excerpt
            author {
                name
            }
            date
            featuredImage {
                mediaItemUrl
            }
        }
    }
}
`

